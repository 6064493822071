<template>
  <div>
    <a-row type="flex" :gutter="[24, 24]" justify="start" align="middle">
      <!-- Walkathon In Form Column -->
      <a-col
        :span="24"
        :md="12"
        :lg="{ span: 24, offset: 0 }"
        :xl="{ span: 24, offset: 0 }"
        class="col-form"
      >
        <!-- Walkathon In Form -->
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
        >
          <a-row>
            <a-col :span="11" :offset="1">
              <a-form-item class="mb-10" label="Name" :colon="true">
                <a-input
                  v-decorator="[
                    'walkathon.nameEn',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input walkathon english name!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Walkathon Name English"
                />
              </a-form-item>
              <a-form-item class="mb-0" label="" :colon="false">
                <a-input
                  v-decorator="[
                    'walkathon.nameMy',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input walkathon myanmar name!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Walkathon Name Myanmar"
                />
              </a-form-item>
              <a-row>
                <a-col :span="11" :offset="0">
                  <a-form-item label="Start Date" class="mb-0">
                    <a-date-picker
                      v-decorator="[
                        'walkathon.startDate',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Please input walkathon start date!',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="11" :offset="1">
                  <a-form-item label="End Date" class="mb-0">
                    <a-date-picker
                      v-decorator="[
                        'walkathon.endDate',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Please input walkathon end date!',
                            },
                          ],
                        },
                      ]"
                      style="width: 100%"
                    />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="11">
                  <a-form-item class="mb-0" label="Chances" :colon="true">
                    <a-input
                      type="number"
                      v-decorator="[
                        'walkathon.chance',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Please input walkathon chance!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Walkathon Chance"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="11" :offset="1">
                  <a-form-item class="mb-0" label="Max radius" :colon="true">
                    <a-input
                      type="number"
                      v-decorator="[
                        'walkathon.max_radius',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Please input walkathon max radius!',
                            },
                          ],
                        },
                      ]"
                      placeholder="Walkathon Radius"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-form-item label="Publish">
                <a-switch v-model:checked="isPublish" />
              </a-form-item>

              <a-form-item>
                <a-button
                  type="primary"
                  block
                  html-type="submit"
                  class="login-form-button"
                >
                  Create Walkathon
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="11" :offset="1">
              <h4>Location List</h4>
              <div v-for="{ location, index } in locations" :key="index">
                <a-form-item class="mb-10" label="Point Name" :colon="true">
                  <a-input
                    id="`location_${index}`"
                    v-decorator="[
                      'pointName',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please input location name!',
                          },
                        ],
                      },
                    ]"
                    placeholder="Location point name"
                  />
                </a-form-item>
              </div>
            </a-col>
          </a-row>

          <!-- / Walkathon In Form -->
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";

export default {
  data() {
    return {
      walkathon: {
        nameEn: "",
        nameEn: "",
        startDate: "",
        endDate: "",
        distance: 0,
      },
      locations: [{}, {}],
      isPublish: false,
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "walathon_create" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("values", values);
        if (!err) {
          //   sendRequest("post", "walkathons", values)
          //     .then((response) => {
          //       if (response.success) {
          //         console.log(response);
          //       } else {
          //         message.error(response.message);
          //       }
          //     })
          //     .catch(() => {})
          //     .finally(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
