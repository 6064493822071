var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":[24, 24],"justify":"start","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{ span: 24, offset: 0 },"xl":{ span: 24, offset: 0 }}},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":11,"offset":1}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Name","colon":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'walkathon.nameEn',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input walkathon english name!',
                      } ],
                  } ]),expression:"[\n                  'walkathon.nameEn',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input walkathon english name!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Walkathon Name English"}})],1),_c('a-form-item',{staticClass:"mb-0",attrs:{"label":"","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'walkathon.nameMy',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input walkathon myanmar name!',
                      } ],
                  } ]),expression:"[\n                  'walkathon.nameMy',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input walkathon myanmar name!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Walkathon Name Myanmar"}})],1),_c('a-row',[_c('a-col',{attrs:{"span":11,"offset":0}},[_c('a-form-item',{staticClass:"mb-0",attrs:{"label":"Start Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'walkathon.startDate',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please input walkathon start date!',
                          } ],
                      } ]),expression:"[\n                      'walkathon.startDate',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Please input walkathon start date!',\n                          },\n                        ],\n                      },\n                    ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"span":11,"offset":1}},[_c('a-form-item',{staticClass:"mb-0",attrs:{"label":"End Date"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'walkathon.endDate',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please input walkathon end date!',
                          } ],
                      } ]),expression:"[\n                      'walkathon.endDate',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Please input walkathon end date!',\n                          },\n                        ],\n                      },\n                    ]"}],staticStyle:{"width":"100%"}})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":11}},[_c('a-form-item',{staticClass:"mb-0",attrs:{"label":"Chances","colon":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'walkathon.chance',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please input walkathon chance!',
                          } ],
                      } ]),expression:"[\n                      'walkathon.chance',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Please input walkathon chance!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"type":"number","placeholder":"Walkathon Chance"}})],1)],1),_c('a-col',{attrs:{"span":11,"offset":1}},[_c('a-form-item',{staticClass:"mb-0",attrs:{"label":"Max radius","colon":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'walkathon.max_radius',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Please input walkathon max radius!',
                          } ],
                      } ]),expression:"[\n                      'walkathon.max_radius',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Please input walkathon max radius!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"type":"number","placeholder":"Walkathon Radius"}})],1)],1)],1),_c('a-form-item',{attrs:{"label":"Publish"}},[_c('a-switch',{model:{value:(_vm.isPublish),callback:function ($$v) {_vm.isPublish=$$v},expression:"isPublish"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" Create Walkathon ")])],1)],1),_c('a-col',{attrs:{"span":11,"offset":1}},[_c('h4',[_vm._v("Location List")]),_vm._l((_vm.locations),function(ref){
                    var location = ref.location;
                    var index = ref.index;
return _c('div',{key:index},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Point Name","colon":true}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'pointName',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input location name!',
                        } ],
                    } ]),expression:"[\n                    'pointName',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Please input location name!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"id":"`location_${index}`","placeholder":"Location point name"}})],1)],1)})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }